import React, { memo, useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ReactToPrint from 'react-to-print'
import device from 'current-device'
import { useLocation } from 'react-router-dom'

import './TournamentGridsPrint.scss'
import { selectInitialStage, selectTournament } from '../../redux/selectors'
import { GROUP_TYPE_RR, GROUP_TYPE_OLYMPIC_PLUS } from '../../utils/constants'
import RoundRobinGridPrint from '../RoundRobinGridPrint/RoundRobinGridPrint'
import OlympicPlusGridPrint from '../OlympicPlusGridPrint/OlympicPlusGridPrint'
import NotPlayingGridPrint from '../NotPlayingGridPrint/NotPlayingGridPrint'
import { ReactComponent as TenturLogoIcon } from '../../icons/tentur_logo.svg'
import { ReactComponent as PrintIcon } from '../../icons/print.svg'
import Popup from '../../reusableComponents/Popup/Popup'
import Button from '../../reusableComponents/Button/Button'
import { groupLengthCheck } from '../../views/TournamentGrids/externalFunctions'
import packageJson from '../../../package.json'
import { NODE_TYPE_IN} from '../../utils/constants'

function TournamentGridsPrint() {
	const { type, players, doubles, stages_count, name } = useSelector(selectTournament)
	const initialStage = useSelector(selectInitialStage)
	const componentRef = useRef(null)
	const location = useLocation()

	const [openPrintPopup, setOpenPrintPopup] = useState(false)
	const [grid, setGrid] = useState([])
	const [groupType, setGroupType] = useState(null)

	const stages = useMemo(() => {
		return Array.from({ length: stages_count }, (_, index) => index + 1)
	}, [stages_count])

	const pathSegments = location.pathname.split('/').filter(Boolean)
	const lastValue = pathSegments[pathSegments.length - 1]

	function formatDate() {
		const currentDate = new Date()
		const day = String(currentDate.getDate()).padStart(2, '0')
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const year = currentDate.getFullYear()

		return `${day}.${month}.${year}`
	}

	const reactToPrintTrigger = useCallback(() => {
		return <button className="print-button"><PrintIcon className="print-icon" /></button>
	}, [])

	const handlePrint = useCallback(() => {
		const trigger = reactToPrintTrigger()

		if (trigger.props.onClick) {
			trigger.props.onClick()
		}
	}, [reactToPrintTrigger])

	useEffect(() => {
		const tables = initialStage?.levels?.flatMap((level) =>
			level.groups?.filter((group) => Object.keys(group).length !== 0)
				.map((group) => {
					setGroupType(group.type)

					const players_table = group.nodes
						.flatMap((node) => {
							let playerObject

							if (node.type === NODE_TYPE_IN) {
								if (node.double_uid === null && node.player_uid === null) {
									return null
								} else if (players) {
									playerObject = players.find((player) => player.uid === node.player_uid)
								} else if (doubles) {
									playerObject = doubles.find((doublesPlayer) => doublesPlayer.uid === node.double_uid)
								}

								if (playerObject) {
									return {
										...playerObject,
										nodeUid: node?.uid,
									}
								}
							}

							return null
						}).filter((player) => player !== null)

					return {
						order_number: group.order_number,
						players: players_table,
						matches: group.matches
					}
				})
		)

		setGrid({
			version: packageJson.version,
			environment: window.location.hostname,
			tournament_name: name,
			stage: lastValue,
			levels: tables,
			type_table: type
		})
	}, [initialStage])

	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	// console.log(JSON.stringify(grid))
	// console.log(grid)

	async function fetchData() {
		try {
			const response = await axios.post(process.env.NODE_ENV === 'development' ? 'http://localhost:8080/pdf/generation/' : '/pdf', grid, {
				headers: {
					'Content-Type': 'application/json',
				},
				responseType: 'blob',
			})

			const url = window.URL.createObjectURL(new Blob([response.data]))

			const a = document.createElement('a')
			a.href = url
			a.download = 'generated-file.pdf'
			a.click()
	
			window.URL.revokeObjectURL(url)

			setData(response.data)
		} catch (err) {
			setError(err.message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			{
				groupType === GROUP_TYPE_RR ?
					<button
						onClick={() => fetchData()}
						className="print-button"
					>
						<PrintIcon className="print-icon" />
					</button>
					:
					<>
						{
							device.os === 'ios' || device.os === 'android' ?
								<button
									onClick={() => setOpenPrintPopup(true)}
									className="print-button"
								>
									<PrintIcon className="print-icon" />
								</button>
								:
								<ReactToPrint
									content={() => componentRef.current}
									documentTitle={`${ name } ${ formatDate() }`}
									removeAfterPrint={false}
									trigger={() => <button className="print-button"><PrintIcon className="print-icon" /></button>}
									pageStyle="@page { margin: 5mm; size: A4; } @media print { body { -webkit-print-color-adjust: exact; size: A4; } }"
								/>
						}

						{
							openPrintPopup &&
								<Popup title="Внимание">
									{
										device.os === 'ios' ?
											<React.Fragment>
												<p className="tournament-grids__popup-title">
													На данный момент мы не поддерживаем ваш телефон, воспользуйтесь телефоном на базе Android или десктопом для печати
												</p>
	
												<div className="tournament-grids__popup-buttons">
													<Button
														title="OK"
														onClick={(e) => setOpenPrintPopup(!e)}
														background
													/>
												</div>
											</React.Fragment>
											:
											device.os === 'android' ?
												<React.Fragment>
													<p className="tournament-grids__popup-title">
														Не все браузеры на Android поддерживают корректно печать, если возникнут проблемы, советуем воспользоваться другим браузером или десктопной версией нашего сайта
													</p>
		
													<div className="tournament-grids__popup-buttons">
														<Button
															title="Отмена"
															onClick={(e) => setOpenPrintPopup(!e)}
															background={'white'}
															marginRight
														/>
		
														<ReactToPrint
															content={() => componentRef.current}
															documentTitle={`${name} ${formatDate()}`}
															removeAfterPrint={false}
															trigger={() => (
																<Button
																	title="Печатать"
																	onClick={handlePrint}
																	background
																/>
															)}
															pageStyle="@page { margin: 5mm; size: A4; } @media print { body { -webkit-print-color-adjust: exact; size: A4; } }"
														/>
													</div>
												</React.Fragment>
												:
												null
									}
								</Popup>
						}

						<div className="print-page" style={{ display: 'none' }}>
							<main
								className="tournament-grids"
								ref={componentRef}
							>
								<div className="logo-container" style={{ width: '100px', height: '21px', margin: '0 0 30px 0' }}>
									<TenturLogoIcon className="tentur-logo__icon" />
								</div>
				
								{
									stages.length > 1 && initialStage?.order_number &&
										<p style={{
											color: 'var(--palette-live-grey-0)',
											fontSize: 'var(--font-size-21)',
											fontWeight: 'var(--font-weight-medium)',
											margin: '0 0 16px 0'
										}}>
											Этап { initialStage?.order_number }
										</p>
								}

								{
									initialStage && initialStage?.levels && initialStage?.levels.map((level, levelIndex) => (
										<React.Fragment key={levelIndex}>
											{
												initialStage.levels.length > 1 && groupLengthCheck(initialStage) && (
													<p style={{
														color: 'var(--palette-live-grey-0)',
														fontSize: 'var(--font-size-21)',
														fontWeight: 'var(--font-weight-medium)',
														margin: '10px 0 10px 0'
													}}>
														Уровень { levelIndex + 1 }
													</p>
												)
											}

											{
												level?.groups?.map((grp, index) => {
													return (
														<React.Fragment key={index}>
															{
																grp?.type === GROUP_TYPE_RR ?
																	<RoundRobinGridPrint
																		group={grp}
																	/>
																	:
																	grp?.type === GROUP_TYPE_OLYMPIC_PLUS ?
																		<OlympicPlusGridPrint
																			group={grp}
																		/>
																		:
																		<NotPlayingGridPrint
																			group={grp}
																		/>
															}
														</React.Fragment>
													)
												})
											}
										</React.Fragment>
									))
								}
							</main>
						</div>
					</>
			}
		</>
	)
}

export default memo(TournamentGridsPrint)