import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import './TournamentGrids.scss'
import { useGetStageQuery } from '../../redux/stageService/stageApiSlice'
import {
	selectAuth,
	selectGridToScroll,
	selectInitialStage,
	selectTournament,
	selectTournamentOptions
} from '../../redux/selectors'
import { useProcessStageData } from '../../hooks/stageHooks'
import { getParameters } from '../../utils/functions'
import { GROUP_TYPE_RR, GROUP_TYPE_OLYMPIC_PLUS, FULFILLED } from '../../utils/constants'
import RoundRobinGrid from '../../extendedComponents/RoundRobin/RoundRobinGrid/RoundRobinGrid'
import OlympicPlusGrid from '../../extendedComponents/OlympicPlusGrid/OlympicPlusGrid'
import OlympicPlus2Grid from '../../extendedComponents/OlympicPlus2Grid/OlympicPlus2Grid'
import NotPlayingGrid from '../../extendedComponents/NotPlayingGrid/NotPlayingGrid'
import NoContent from '../../reusableComponents/NoContent/NoContent'
import CombineTournamentMenu from '../../reusableComponents/CombineTournamentMenu/CombineTournamentMenu'
import Loader from '../../reusableComponents/Loader/Loader'
import HeaderTournament from '../../reusableComponents/HeaderTournament/HeaderTournament'
import NoGroups from '../../reusableComponents/NoGroups/NoGroups'
// import TournamentGridsPrint from '../../print/TournamentGridsPrint/TournamentGridsPrint'
import StageGeneration from '../../extendedComponents/Stage/StageGeneration/StageGeneration'
import RemoveStageButton from '../../reusableComponents/RemoveStageButton/RemoveStageButton'
import { isNumeric, processGroup } from '../../utils/functions2'
import { setGridToScroll } from '../../redux/singleTournamentService/singleTournamentSlice'

import { groupLengthCheck } from './externalFunctions'

function TournamentGrids({ tournamentStartDate, category, tournamentRefetch }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid, guest } = getParameters(tournamentParams)
	const { authorized, isGuest } = useSelector(selectAuth)
	const { stages_count, name, players, doubles } = useSelector(selectTournament)
	const gridIdToScroll = useSelector(selectGridToScroll)
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const initialStage = useSelector(selectInitialStage)
	const gridsParentRef = useRef()
	const participants = players || doubles

	const [activeButton, setActiveButton] = useState('new') // classic

	const { data: stageData = {}, status } = useGetStageQuery({
		tournament_uid: tournamentUid,
		stageNumber,
		guest
	}, {
		skip: !stages_count || stages_count < 1
			|| stageNumber < 1 || stageNumber > stages_count || !isNumeric(stageNumber),
		refetchOnMountOrArgChange: stages_count > 0
	})

	useProcessStageData(stageData)

	const gridNameColumnWidth = useMemo(() => {
		let hasOlympic = false
		if (initialStage && initialStage?.levels?.length > 0 ) {
			const lastNamesWidth = []

			// создаем и вставляем дивы с фамилиями,
			// чтобы найти наиболее широкий и подстроить ширину всех остальных

			initialStage?.levels.forEach((item) => {
				item.groups.forEach(grp => {
					if (grp.type === GROUP_TYPE_OLYMPIC_PLUS) {
						hasOlympic = true
					}
					const processedGroup = processGroup(grp, players, doubles)
					processedGroup.forEach(node => {
						if (node.last_name || node.name) {
							const div = document.createElement('div')
							div.style.opacity = 0
							div.style.position = 'absolute'
							div.innerText = node.last_name || node.name
							document.body.appendChild(div)
							const width = div.getBoundingClientRect().width
							lastNamesWidth.push(Math.ceil(width))
							div.remove()
						}
					})
				})
			})

			const extraWidthOlympDoubles = 147
			const extraWidthOlympSingles = 82
			const addedWidth = hasOlympic ? players ? extraWidthOlympSingles : extraWidthOlympDoubles : 15

			return Math.max(...lastNamesWidth) + addedWidth
		}
	}, [initialStage, doubles, players])

	const handleButtonClick = (buttonType) => {
		setActiveButton(buttonType)
	}

	function handleScroll(groupId) {
		dispatch(setGridToScroll(groupId))
	}

	// сортировка участников, когда все матчи сыграны
	// function getGroup(group) {
	// 	const { nodes, results } = sortByRanking(group)

	// 	return { ...group, nodes, results }
	// }

	// можно ли показать сетки. name проверяется, чтобы убедиться, что турнир уже загрузился
	const firstCondition = name && stages_count > 0 && status === FULFILLED && stageData?.levels?.length > 0

	useEffect(() => {
		// оно так работает
		setTimeout(() => {
			if (firstCondition) {
				const block = document.getElementById(gridIdToScroll)

				if (block && gridsParentRef.current) {
					gridsParentRef.current.scrollTo({
						left: 0,
						top: block.offsetTop - 90,
					})

					dispatch(setGridToScroll(null))
				}
			}
		}, 100)
	}, [gridIdToScroll, dispatch, firstCondition])

	// показ NoContent для режима, у которого есть вкладка Структура
	const conditionForWideRegims = options?.modes?.tabsAreVisible && (
		(stages_count > 0 && status === FULFILLED && stageData?.levels?.length < 1) || stages_count < 1 || stageNumber > stages_count
	)

	// показ NoContent для режима, у которого нет вкладки Структура
	const conditionForLimitedRegim = participants?.length < 1 && !options?.modes?.tabsAreVisible

	const secondCondition = (name && options?.modes && (
		conditionForWideRegims || conditionForLimitedRegim)
	) || !authorized || isGuest || stageNumber < 1

	// показ Автогенерации для режима, у которого нет вкладки Структура
	const thirdCondition = name && participants && participants.length > 0
		&& options?.modes && !options?.modes?.tabsAreVisible && (
		(stages_count < 1 && stageNumber < 2) || (stages_count > 1 && stageNumber > 1 && stageData?.levels?.length < 1)
	)  && authorized && !isGuest


	// показ кнопки удаления этапа
	const forthCondition = name && stages_count > 0 && options?.modes
		&& !options?.modes?.tabsAreVisible && authorized && !isGuest && stageNumber <= stages_count && stageNumber > 0

	function isShowToggleOlympic() {
		return stageData?.levels?.some(level => 
			level?.groups?.some(grp => grp?.type === GROUP_TYPE_OLYMPIC_PLUS) && stageData.rating_initial.length <= 32
		)
		||
		false
	}

	return (
		<>
			<HeaderTournament
				date={tournamentStartDate}
				category={category}
				copyLink={authorized && !isGuest ? true : false}
				// printComponent={
				// 	<TournamentGridsPrint />
				// }
				background
			/>

			<main
				ref={gridsParentRef}
				className={
					`tournament-grids__container ${initialStage && initialStage?.levels?.length > 0 ? 'filled' : ''}`
				}
			>
				<div className="tournament-grids">
					{
						isShowToggleOlympic() ?
							<div
								className="tournament-switch__container"
								style={{ opacity: gridIdToScroll ? 0 : 1 }} // чтобы не дергался экран при скроле к группе
							>
								<div className="tournament-switch__wrapper">
									<button
										className={`tournament-switch__button ${
											activeButton === 'new' ? 'tournament-switch__button--active' : ''
										}`}
										onClick={() => handleButtonClick('new')}
									>
										Новая
									</button>

									<button
										className={`tournament-switch__button ${
											activeButton === 'classic' ? 'tournament-switch__button--active' : ''
										}`}
										onClick={() => handleButtonClick('classic')}
									>
										Классическая
									</button>
								</div>
							</div>
							:
							null
					}

					{
						firstCondition ?
							stageData?.levels?.map((level, levelIndex) => {
								return (
									<div
										className="tournament-grids__level"
										key={levelIndex}
										style={{ opacity: gridIdToScroll ? 0 : 1 }} // чтобы не дергался экран при скроле к группе
									>
										{
											stageData?.levels.length > 1 && groupLengthCheck(stageData) &&
												<p className="tournament-grids__level-name">
													{`${t('Level')} ${levelIndex + 1}`}
												</p>
										}

										{
											level?.groups.length > 0 ?
												<div>
													{
														level?.groups?.map((grp, index) => {
															return (
																<div
																	key={index}
																	id={`grid-group-${levelIndex}-${index}`}
																	className="tournament-grids__level-group"
																>
																	{
																		grp?.type === GROUP_TYPE_RR ?
																			<RoundRobinGrid
																				group={grp}
																				gridNameColumnWidth={gridNameColumnWidth}
																				setScroll={() => handleScroll(`grid-group-${levelIndex}-${index}`)}
																			/>
																			:
																			grp?.type === GROUP_TYPE_OLYMPIC_PLUS ?
																				activeButton === 'new' ?
																					<OlympicPlusGrid
																						group={grp}
																						gridNameColumnWidth={gridNameColumnWidth}
																						setScroll={() => handleScroll(`grid-group-${levelIndex}-${index}`)}
																					/>
																					:
																					<OlympicPlus2Grid
																						group={grp}
																						gridNameColumnWidth={gridNameColumnWidth}
																						setScroll={() => handleScroll(`grid-group-${levelIndex}-${index}`)}
																					/>
																				:
																				<NotPlayingGrid
																					gridNameColumnWidth={gridNameColumnWidth}
																					group={grp}
																				/>
																	}
																</div>
															)
														})}
												</div>
												:
												<NoGroups />
										}
									</div>
								)
							})
							: secondCondition ?
								<NoContent
									table={'Сеток'}
									noStage={
										(Number(stageNumber) !== 1 && stages_count === 0)
										|| (stages_count > 0 && Number(stageNumber) > stages_count)
										|| stageNumber < 1
									}
								/>
								: thirdCondition ?
									<StageGeneration
										tournamentRefetch={tournamentRefetch}
									/>
									:
									<Loader />
					}
				</div>

				{
					forthCondition &&
						<RemoveStageButton tournamentRefetch={tournamentRefetch} />
				}
			</main>

			<CombineTournamentMenu />
		</>
	)
}

export default memo(TournamentGrids)