import { validate } from 'react-email-validator'

import { resetAuth } from '../redux/authService/authSlice'
import { setCommunityUid } from '../redux/communityService/communitySlice'

import { ALPHABET, EXPIRES_MS_AMOUNT_LIMIT, NODE_TYPE_IN, TYPE_DOUBLES, TYPE_SINGLES } from './constants'

function getLocalStorageItem(key) {
	const item = localStorage.getItem(key)
	return item ? item : null
}

function setItemInLocalStorage(key, item) {
	localStorage.setItem(key, item)
}

function removeItemInLocalStorage(key) {
	localStorage.removeItem(key)
}

function getSessionStorageItem(key) {
	const item = sessionStorage.getItem(key)
	return item ? item : null
}

function setItemInSessionStorage(key, item) {
	sessionStorage.setItem(key, item)
}

function parseObject(value, callBack) {
	try {
		const obj = callBack(value)
		const parsedObj = obj && obj !== 'undefined' ? JSON.parse(obj) : null
		return parsedObj
	} catch (e) {
		console.log('Parse object error', e)
	}
}

function removeItemInSessionStorage(key) {
	localStorage.removeItem(key)
}

const moldPlayersNames = (array, type) => {
	let participants = [...array]
	const sortedParticipants = participants.sort((a, b) => a.uid.localeCompare(b.uid))
	// возможно сортировка по uid уже не нужна, но зачем-то она была нужна, но я не помню почему

	if (type === TYPE_SINGLES) {
		participants = moldSinglePlayersNames(sortedParticipants)
	} else {
		participants = moldPlayersNamesInDoubles(sortedParticipants)
	}

	return getParticipantsInOriginalOrder(array, participants, type)
}

function getParticipantsInOriginalOrder(startArr, endArr, type) {
	return startArr.map(item => {
		const endItem = endArr.find(el => el.uid === item.uid)

		if (type === TYPE_SINGLES) {
			return {
				...item,
				formedName: endItem?.formedName
			}
		} else {
			return {
				...item,
				player1: endItem.player1,
				player2: endItem.player2
			}
		}
	})
}

const moldSinglePlayersNames = (players) => {
	let samePlayers = {}

	const formedNames = players.map((player) => {
		// let displayName = player?.display_name || null когда сервер начнет делать ники нормально
		let displayName = null

		if (!displayName) {
			const [
				formedName,
				duplicates
			] = getFormedName(players, player, samePlayers)
			samePlayers = duplicates
			displayName = formedName
		}

		return { ...player, formedName: displayName }
	})

	return formedNames
}

const moldPlayersNamesInDoubles = (doubles) => {
	const playersArray = []

	doubles.forEach((double) => {
		playersArray.push(double.player1)
		playersArray.push(double.player2)
	})

	let samePlayers = {}

	const formedNames = doubles.map((d) => {
		const playerOne = { ...d.player1 }
		const playerTwo = { ...d.player2 }

		let displayNameOne = playerOne?.display_name
		let displayNameTwo = playerTwo?.display_name

		if (!displayNameOne) {
			const [
				fullNameOne,
				sameOne
			] = getFormedName(playersArray, playerOne, samePlayers)
			samePlayers = sameOne
			displayNameOne = fullNameOne
		}

		if (!displayNameTwo) {
			const [
				fullNameTwo,
				sameTwo
			] = getFormedName(playersArray, playerTwo, samePlayers)
			samePlayers = sameTwo
			displayNameTwo = fullNameTwo
		}

		playerOne.formedName = displayNameOne
		playerTwo.formedName = displayNameTwo

		return { ...d, player1: playerOne, player2: playerTwo }
	})
	return formedNames
}

function cutName(name) {
	// обрезаем, чтобы в таблицу влезло имя как дизайнер сказала
	if (name.length > 8) {
		const cutStr = name.slice(0, 7)
		return cutStr
	} else {
		return name
	}
}

const getFormedName = (playersArray, player, samePlayers) => {
	// добавление нумерации если игроки имеют одинаковые фамилии
	const cutPlayerLastName = cutName(player.last_name)

	const foundPlayers = playersArray.filter(el =>
		cutName(el?.last_name) === cutPlayerLastName
	)

	let fullName = player.last_name

	if (foundPlayers.length > 1) {
		if (fullName in samePlayers) {
			samePlayers[fullName] = samePlayers[fullName] + 1
			fullName = `${cutPlayerLastName}${samePlayers[fullName]}`
		} else {
			samePlayers[fullName] = 1
			fullName = `${cutPlayerLastName}1`
		}
	}

	return [fullName, samePlayers]
}

const getNewUid = () => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	let randomValue = ''

	for (let i = 0; i < 6; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length)
		randomValue += characters[randomIndex]
	}
	return randomValue
}

const assignAlphabeticalNames = (stage) => {
	let counter = 0

	const levelsWithNamedGroups = stage?.levels?.map(l => {
		const namedGroups = l?.groups?.map((g) => {
			if (g.name === null) {
				const namedGroup = { ...g, name: ALPHABET[counter] }
				counter++
				return namedGroup
			} else {
				return g
			}
		})
		return { ...l, groups: namedGroups }
	})
	return { ...stage, levels: levelsWithNamedGroups }
}

const moldSearchableWord = (word) => {
	// form searchable word
	return word?.toLowerCase().replace(/ +/g, '').replace(/ё/, 'е')
}

const findPlayers = (searchText, listOfPlayers) => {
	// looking for participants through the search
	if (searchText === '') {
		return listOfPlayers
	} else {
		return listOfPlayers?.filter((player) => {
			return moldSearchableWord(player?.first_name).startsWith(moldSearchableWord(searchText)) ||
				moldSearchableWord(player?.last_name).startsWith(moldSearchableWord(searchText)) ||
				moldSearchableWord(`${player?.last_name} ${player?.first_name}`).startsWith(moldSearchableWord(searchText)) ||
				moldSearchableWord(`${player?.first_name} ${player?.last_name}`).startsWith(moldSearchableWord(searchText))
		})
	}
}

const checkEquality = (obj1, obj2) => {
	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
		return obj1 === obj2
	}

	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)

	if (keys1.length !== keys2.length) {
		return false
	}

	for (const key of keys1) {
		if (!keys2.includes(key)) {
			return false
		}

		if (!checkEquality(obj1[key], obj2[key])) {
			return false
		}
	}

	return true
}

function isValidEmail(email) {
	const emailString = email.replace(/[.@_+]/g, '')
	const regexp = /^[A-Za-z0-9]+$/

	if (regexp.test(emailString)) {
		return validate(email)
	} else {
		return false
	}
}

function getParameters(path) {
	// это чтобы вычленять uid турнира и гостевой код
	if (!path) {
		return { tournamentUid: null, guest: null }
	}

	if (!path.includes('-')) {
		return { tournamentUid: path, guest: null }
	}

	const parametrs = path.split('-')

	return { tournamentUid: parametrs[0], guest: parametrs[1] }
}

function generateGroupOrLevelsName(index) {
	const groupName = ALPHABET[index]

	return groupName
}

function resetAuthorization(dispatch, communityUid, wipeReason) {
	dispatch(resetAuth())
	dispatch(setCommunityUid(communityUid))
	setItemInLocalStorage('storage_wipe_reason', wipeReason)
}

function tokenIsNotExpired(tokenDieAt, timeDelta) {
	// проверка когда токен помрет
	if (!tokenDieAt || (timeDelta === undefined || timeDelta === null || timeDelta === '')) {
		return false
	}

	const currentTimeUTC = new Date()
	const timeWithDelta = currentTimeUTC.getTime() + timeDelta
	const timeLeft = tokenDieAt - (timeWithDelta + EXPIRES_MS_AMOUNT_LIMIT)

	// console.log('tokenIsNotExpired ', {
	// 	timeDelta,
	// 	formattedCurrentTime: currentTimeUTC,
	// 	timeWithDelta,
	// 	tokenDieAt: tokenDieAt,
	// 	timeLeft: timeLeft
	// })

	if (timeLeft <= 0) {
		// console.log('оставшееся время меньше или равно 0')
		return false
	} else {
		// console.log('оставшееся время больше лимита')
		return true
	}
}

function formLevels(stage, players, type) {
	// формируем уровни этапа так, чтобы в нодах были участники
	let totalNodesQuantity = 0

	const newLevels = stage?.levels?.map(lvl => {
		const newGroups = lvl?.groups?.map((grp) => {
			const nodes = grp?.nodes?.filter(node => node?.type === NODE_TYPE_IN)

			totalNodesQuantity = totalNodesQuantity + nodes?.length

			const formedParticipants = nodes.map((node) => {
				return getformedNode(players, node, type, stage)
			})

			const updateGroup = { ...grp, nodes: formedParticipants, uid: getNewUid() }
			return updateGroup
		})

		return { ...lvl, groups: newGroups, uid: getNewUid() }
	})

	return { levels: newLevels, totalNodesQuantity }
}

function formNodes(stage, players, type) {
	const initialRaiting = stage?.rating_initial || createInitialRaiting(players)

	return initialRaiting?.map(node => {
		const avaliableNode = { ...node, disabled: false }

		return getformedNode(players, avaliableNode, type, stage)
	}) || []
}

function createInitialRaiting(players) {
	// для первого этапа сами создаем предэтапный рейтинг
	return players.map((p, index) => {
		return {
			bye: true,
			double_uid: p?.uid || null,
			name: index + 1,
			player_uid: p?.uid || null,
			uid: getNewUid(),
			type: 'incoming'
		}
	})
}

function getformedNode(players, node, type, stage) {
	if (type === TYPE_SINGLES) {
		const participant = players?.find((p) => p.uid === node?.player_uid)
		let rating = null

		if (participant?.formedName) {
			rating = node?.name
		}

		return {
			dependsOnUid: node.depends_on_uid || null,
			placed: checkParticipantPlace(stage, node),
			nodeUid: node?.uid,
			playerUid: participant?.uid || null,
			bye: node?.bye,
			disabled: node?.disabled,
			type: node?.type,
			participant: {
				formedName: participant?.formedName || null,
				lastName: participant?.last_name || null,
				firstName: participant?.first_name || null
			},
			name: node.name,
			rating
		}
	} else if (type === TYPE_DOUBLES) {
		const participant = players?.find((p) => p.uid === node?.double_uid)
		let rating = null

		if (participant) {
			rating = node?.name
		}

		return {
			dependsOnUid: node.depends_on_uid || null,
			placed: checkParticipantPlace(stage, node),
			doubleUid: node?.double_uid || null,
			nodeUid: node?.uid,
			type: node?.type,
			bye: node?.bye,
			disabled: node?.disabled,
			rating,
			participant: {
				firstPlayerUid: participant?.player1?.uid || null,
				secondPlayerUid: participant?.player2?.uid || null,

				firstPlayerName: participant?.player1?.formedName || null,
				lastName1: participant?.player1?.last_name || null,
				firstName1: participant?.player1?.first_name || null,

				secondPlayerName: participant?.player2?.formedName || null,
				lastName2: participant?.player2?.last_name || null,
				firstName2: participant?.player2?.first_name || null,
			},
			name: node.name
		}
	}
}

function checkParticipantPlace(stage, initialRatingNode, processedStage) {
	// проверяет размещен ли участник в группе или еще висит в списке без дела
	if (stage.levels.length > 0) {
		return stage.levels.some(lvl =>
			lvl.groups.some(grp =>
				grp.nodes.some(node => {
					if (node.type === NODE_TYPE_IN) {
						if (!processedStage) {
							return (node.double_uid !== null && node.double_uid === initialRatingNode.double_uid) ||
								(node.player_uid !== null && node.player_uid === initialRatingNode.player_uid) ||
								(!node.double_uid && !initialRatingNode.double_uid
									&& !initialRatingNode.player_uid && !node.player_uid && node.name === initialRatingNode.name)
						} else {
							return (node.doubleUid && node.doubleUid === initialRatingNode.doubleUid) ||
								(node.playerUid && node.playerUid === initialRatingNode.playerUid) ||
								(!node.doubleUid && !initialRatingNode.doubleUid
									&& !initialRatingNode.playerUid && !node.playerUid && node.name === initialRatingNode.name)
						}
					} else {
						return false
					}
				}
				)
			)
		)
	} else {
		return false
	}
}

function updateGroupsAndLevelsOrderNumber(levels) {
	let groupOrderNumber = 0
	let levelsOrderNumber = 0

	return levels.map(lvl => {
		levelsOrderNumber++
		return {
			...lvl,
			order_number: levelsOrderNumber,
			groups: lvl.groups.map(grp => {
				groupOrderNumber++
				return {
					...grp,
					order_number: groupOrderNumber
				}
			})
		}
	})
}

export {
	getLocalStorageItem,
	setItemInLocalStorage,
	removeItemInLocalStorage,
	parseObject,
	moldPlayersNames,
	getNewUid,
	assignAlphabeticalNames,
	findPlayers,
	checkEquality,
	isValidEmail,
	getParameters,
	generateGroupOrLevelsName,
	resetAuthorization,
	tokenIsNotExpired,
	formLevels,
	formNodes,
	getformedNode,
	updateGroupsAndLevelsOrderNumber,
	getSessionStorageItem,
	removeItemInSessionStorage,
	setItemInSessionStorage,
	checkParticipantPlace,
	moldSearchableWord
}