/* eslint-disable quotes */
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router'

import './OlympicPlus2Grid.scss'
import { formGrid } from '../OlympicPlusGrid/externalFunctions'
import { FINAL_COLUMN_NAME, RESULT_COLUMN_NAME, TYPE_SINGLES, TYPE_DOUBLES } from '../../utils/constants'
import { generateGroupOrLevelsName, getParameters } from '../../utils/functions'
import { selectTournament, selectAuth, selectTournamentOptions } from '../../redux/selectors'
import { setMatchData, setMatchSession } from '../../redux/matchService/matchSlice'
import { addFirstSet } from '../../views/Match/externalFunctions'
import Tooltip from '../../reusableComponents/Tooltip/Tooltip'

import { converterApiToOlympicFormat } from './externalFunctions'

function OlympicPlus2Grid({ group, gridNameColumnWidth, setScroll }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { type, players, doubles } = useSelector(selectTournament)
	const { authorized, isGuest } = useSelector(selectAuth)
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { stage: options = {} } = useSelector(selectTournamentOptions)

	const grid = useMemo(() => {
		return formGrid(group)
	}, [group])

	// Не удалять
	// console.log(converterApiToOlympicFormat(grid))

	// Не удалять
	// console.log(grid)

	// Не удалять
	// console.log(JSON.stringify(grid))

	const participantsMap = useMemo(() => {
		return new Map((players || doubles)?.map(item =>
			[item.uid, item]
		))
	}, [players, doubles])

	function getLoser(match) {
		const noLoser = null

		if (!match.match || !match.matchData.is_finished) return noLoser

		const { matchData } = match
		const { sets, side1_uid, side2_uid } = matchData

		let score1 = 0
		let score2 = 0

		sets.forEach(item => {
			const pointsOne = item.score1 + item.tie_break_score1
			const pointsTwo = item.score2 + item.tie_break_score2

			if (pointsOne > pointsTwo) {
				score1++
			} else if (pointsOne < pointsTwo) {
				score2++
			}
		})

		if (score1 > score2) {
			return side2_uid
		} else if (score1 < score2) {
			return side1_uid
		} else {
			return noLoser
		}
	}

	function getSidesLayout(cellMatch, roundName, columnIndex) {
		const { nodeOne, nodeTwo } = cellMatch
		const uidOne = nodeOne?.player_uid || nodeOne?.double_uid
		const uidTwo = nodeTwo?.player_uid || nodeTwo?.double_uid
		const insteadOfNameOne = nodeOne?.name || <span className="empty-player"></span>
		const insteadOfNameTwo = nodeTwo?.name || <span className="empty-player"></span>
		const isLoser = getLoser(cellMatch)

		if (type === TYPE_SINGLES) {
			// Сингл
			const singleOne = participantsMap.get(uidOne)
			const singleTwo = participantsMap.get(uidTwo)

			return (
				<div className="team" >
					<div className="team-wrapper">
						<div className="team-number-wrapper">
							<p className="team-number">
								{ cellMatch?.matchNumber }
							</p>
						</div>

						<div className="team-name-wrapper">
							<Tooltip
								content={`${singleOne?.last_name} ${singleOne?.first_name || ''} ${singleOne?.middle_name || ''}`}
								disable={singleOne?.formedName ? false : true}
							>
								<p className="team-name-title" style={ isLoser === nodeOne?.uid ? { color: 'var(--palette-live-grey-9)' } : {}}>
									{ singleOne?.formedName || insteadOfNameOne }
								</p>
							</Tooltip>

							<Tooltip
								content={`${singleTwo?.last_name} ${singleTwo?.first_name || ''} ${singleTwo?.middle_name || ''}`}
								disable={singleTwo?.formedName ? false : true}
							>
								<p className="team-name-title" style={ isLoser === nodeTwo?.uid ? { color: 'var(--palette-live-grey-9)' } : {}}>
									{ singleTwo?.formedName || insteadOfNameTwo }
								</p>
							</Tooltip>
						</div>
					</div>

					<div className="team-score-wrapper">
						{ getSets(cellMatch) }
					</div>
				</div>
			)
		} else {
			// Дабл
			const doubleOne = {
				firstPlayer: participantsMap.get(uidOne)?.player1,
				secondPlayer: participantsMap.get(uidOne)?.player2
			}
			const doubleTwo = {
				firstPlayer: participantsMap.get(uidTwo)?.player1,
				secondPlayer: participantsMap.get(uidTwo)?.player2
			}

			return (
				<div className="team" style={{ height: '104px' }}>
					<div className="team-wrapper">
						<div className="team-number-wrapper">
							<p className="team-number">
								{ cellMatch?.matchNumber }
							</p>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', flex: '1', justifyContent: 'center' }}>
							<div className="team-name-wrapper" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
								{
									uidOne ?
										<>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													...(isLoser === nodeOne?.uid ? { color: 'var(--palette-live-grey-9)' } : {}),
												}}
											>
												<Tooltip
													content={`${doubleOne.firstPlayer?.last_name} ${doubleOne.firstPlayer?.first_name || ''} ${doubleOne.firstPlayer?.middle_name || ''}`}
													disable={doubleOne.firstPlayer?.formedName ? false : true}
												>
													<p className="team-name-title">
														{ doubleOne.firstPlayer?.formedName }
													</p>
												</Tooltip>

												<Tooltip
													content={`${doubleOne.secondPlayer?.last_name} ${doubleOne.secondPlayer?.first_name || ''} ${doubleOne.secondPlayer?.middle_name || ''}`}
													disable={doubleOne.secondPlayer?.formedName ? false : true}
												>
													<p className="team-name-title">
														{ doubleOne.secondPlayer?.formedName }
													</p>
												</Tooltip>
											</div>

											<div className="team-name__sets-wrapper">
												{ getSets(cellMatch, 'firstPair') }
											</div>
										</>
										:
										<p className="team-name-title" style={{ marginTop: '7px' }}>
											{ insteadOfNameOne }
										</p>
								}
							</div>

							<div className="team-name-wrapper" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
								{
									uidTwo ?
										<>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													...(isLoser === uidTwo?.uid ? { color: 'var(--palette-live-grey-9)' } : {}),
												}}
											>
												<Tooltip
													content={`${doubleTwo.firstPlayer?.last_name} ${doubleTwo.firstPlayer?.first_name || ''} ${doubleTwo.firstPlayer?.middle_name || ''}`}
													disable={doubleTwo.firstPlayer?.formedName ? false : true}
												>
													<p className="team-name-title">
														{ doubleTwo.firstPlayer?.formedName }
													</p>
												</Tooltip>

												<Tooltip
													content={`${doubleTwo.secondPlayer?.last_name} ${doubleTwo.secondPlayer?.first_name || ''} ${doubleTwo.secondPlayer?.middle_name || ''}`}
													disable={doubleTwo.secondPlayer?.formedName ? false : true}
												>
													<p className="team-name-title">
														{ doubleTwo.secondPlayer?.formedName }
													</p>
												</Tooltip>
											</div>

											<div className="team-name__sets-wrapper">
												{ getSets(cellMatch, 'secondPair') }
											</div>
										</>
										:
										<p className="team-name-title" style={{ marginTop: '7px' }}>
											{ insteadOfNameTwo }
										</p>
								}
							</div>
						</div>
					</div>
				</div>
			)
		}
	}

	function getParticipantForResult(cellMatch) {
		if (type === TYPE_SINGLES) {
			// Сингл
			return (
				<div className="result"  style={{ minWidth: gridNameColumnWidth + 45 + 'px' }}>
					{
						<div className="result-wrapper">
							{
								cellMatch.map((match, index) => {
									const uid = match?.player_uid || match?.double_uid
									const participant = participantsMap.get(uid)
									const insteadOfName = match.name || ''

									return (
										insteadOfName !== '' && (
											<div
												className="result-name-wrapper"
												key={index}
												style={{
													backgroundColor: (index + 1) % 2 === 0
														? 'var(--palette-live-grey-8)'
														: 'var(--palette-live-bg-0-white)'
												}}
											>
												<p className="result-name-title">
													{
														uid ?
															participant?.formedName
															:
															insteadOfName
													}
												</p>

												<div className="result-name-number">
													{ match.order_number }
												</div>
											</div>
										)
									)
								})
							}
						</div>
					}
				</div>
			)
		} else {
			// Дабл
			const uidOne = cellMatch[0]?.player_uid || cellMatch[0]?.double_uid
			const uidTwo = cellMatch[1]?.player_uid || cellMatch[1]?.double_uid

			const doubleOne = {
				firstPlayer: participantsMap.get(uidOne)?.player1,
				secondPlayer: participantsMap.get(uidOne)?.player2
			}
			const doubleTwo = {
				firstPlayer: participantsMap.get(uidTwo)?.player1,
				secondPlayer: participantsMap.get(uidTwo)?.player2
			}

			const insteadOfNameOne = cellMatch[0]?.name || ''
			const insteadOfNameTwo = cellMatch[1]?.name || ''

			const orderNumberOne = cellMatch[0]?.order_number
			const orderNumberTwo = cellMatch[1]?.order_number

			return (
				<div className="result--double">
					<div className="result-name-wrapper--double">
						{
							uidOne ?
								<div className="result-name-container--double">
									<p className="result-name-title--double-first">
										{ doubleOne.firstPlayer.formedName }
									</p>
	
									<p className="result-name-title--double-second">
										{ doubleOne.secondPlayer.formedName }
									</p>
								</div>
								:
								<div className="result-name-container--double">
									<p className="result-name-title--double-first">
										{ insteadOfNameOne }
									</p>
								</div>
						}

						<div className="result-name-number--double">
							{ orderNumberOne }
						</div>
					</div>

					<div className="result-name-wrapper--double">
						{
							uidTwo ?
								<div className="result-name-container--double">
									<p className="result-name-title--double-first">
										{ doubleTwo.firstPlayer.formedName }
									</p>

									<p className="result-name-title--double-second">
										{ doubleTwo.secondPlayer.formedName }
									</p>
								</div>
								:
								<div className="result-name-container--double">
									<p className="result-name-title--double-first">
										{ insteadOfNameTwo }
									</p>
								</div>
						}

						<div className="result-name-number--double">
							{ orderNumberTwo }
						</div>
					</div>
				</div>
			)
		}
	}

	function matchupHeight(cell) {
		let height = ''

		if (type === TYPE_SINGLES) {
			if (cell.size === 1) {
				height = '55px'
			}
			else if (cell.size === 2) {
				height = '120px'
			}
			else if (cell.size === 4) {
				height = '245px'
			}
			else if (cell.size === 8) {
				height = '490px'
			}
			else if (cell.size === 9) {
				height = '1010px'
			}
		} else {
			if (cell.size === 1) {
				height = '106px'
			}
			else if (cell.size === 2) {
				height = '226px'
			}
			else if (cell.size === 4) {
				height = '450px'
			}
			else if (cell.size === 8) {
				height = '905px'
			}
			else if (cell.size === 9) {
				height = '1820px'
			}
		}

		return height
	}

	function matchupContainer(pair) {
		let style = {}

		if (type === TYPE_SINGLES) {
			if (pair.size === 4) {
				style = { height: '244px' }
			}

			if (pair.margin === -1) {
				style = { marginTop: '-25px' }
			}

			if (pair.margin === 3) {
				style = { marginTop: '75px' }
			}

			if (pair.margin === 4) {
				style = { marginTop: '95px' }
			}

			if (pair.margin === 4.1) {
				style = { marginTop: '130px' }
			}

			if (pair.margin === 4.2) {
				style = { marginTop: '160px' }
			}

			if (pair.margin === 5) {
				style = { marginTop: '260px' }
			}

			if (pair.margin === 6) {
				style = { marginTop: '280px' }
			}

			if (pair.margin === 7) {
				style = { marginTop: '770px' }
			}
		} else {
			if (pair.size === 4) {
				style = { height: '430px' }
			}

			if (pair.margin === 5) {
				style = { marginTop: '428px' }
			}

			if (pair.margin === 4) {
				style = { marginTop: '128px' }
			}

			if (pair.margin === 3) {
				style = { marginTop: '120px' }
			}

			if (pair.margin === 4.1) {
				style = { marginTop: '235px' }
			}

			if (pair.margin === 4.2) {
				style = { marginTop: '230px' }
			}

			if (pair.margin === 6) {
				style = { marginTop: '496px' }
			}

			if (pair.margin === 7) {
				style = { marginTop: '1332px' }
			}
		}

		return style
	}

	function goToMatch(match) {
		const { matchData, nodeOne, nodeTwo } = match

		const matchNotExists = !matchData || (
			(type === TYPE_SINGLES && (!nodeOne?.player_uid || !nodeTwo?.player_uid))
			||
			(type === TYPE_DOUBLES && (!nodeOne?.double_uid || !nodeTwo?.double_uid))
		)

		if (matchNotExists || !authorized || isGuest) return

		setScroll()

		const updatedMatch = formMatch(match)
		updatedMatch.pathBack = location.pathname
		dispatch(setMatchData(updatedMatch))
		dispatch(setMatchSession(updatedMatch))
		navigate('/t/' + tournamentUid + '/draws/' + stageNumber + '/match')
	}

	function formMatch(match) {
		const { matchData, nodeOne, nodeTwo } = match

		const uidOne = nodeOne?.player_uid || nodeOne?.double_uid
		const uidTwo = nodeTwo?.player_uid || nodeTwo?.double_uid

		let updatedMatch = addFirstSet(matchData, options)

		if (type === TYPE_SINGLES) {
			const singleOne = participantsMap.get(uidOne)?.formedName
			const singleTwo = participantsMap.get(uidTwo)?.formedName

			updatedMatch = {
				...updatedMatch,
				side1: {
					namePlayer: singleOne,
					nodeUid: updatedMatch?.side1_uid
				},
				side2: {
					namePlayer: singleTwo,
					nodeUid: updatedMatch?.side2_uid
				},
				uid: updatedMatch?.uid
			}
		} else {
			const doubleOne = {
				firstPlayer: participantsMap.get(uidOne)?.player1.formedName,
				secondPlayer: participantsMap.get(uidOne)?.player2.formedName
			}
			const doubleTwo = {
				firstPlayer: participantsMap.get(uidTwo)?.player1.formedName,
				secondPlayer: participantsMap.get(uidTwo)?.player2.formedName
			}

			updatedMatch = {
				...updatedMatch,
				side1: {
					namePlayer1: doubleOne.firstPlayer,
					namePlayer2: doubleOne.secondPlayer,
					nodeUid: updatedMatch.side1_uid
				},
				side2: {
					namePlayer1: doubleTwo.firstPlayer,
					namePlayer2: doubleTwo.secondPlayer,
					nodeUid: updatedMatch.side2_uid
				},
				uid: updatedMatch?.uid
			}
		}

		return updatedMatch
	}

	function getSets(match, pairType) {
		const matchData = match?.matchData
	
		if (matchData?.sets.length > 0) {
			const sets = matchData?.sets

			if (type === TYPE_SINGLES) {
				return sets.map((set, index) => {
					return (
						<div className={`team-score-container ${type !== TYPE_SINGLES ? 'team-score-container--double' : ''} `} key={index} onClick={() => goToMatch(match)}>
							<div className="team-score__wrapper">
								<p className="team-score-number">
									{ set.score1 }
								</p>

								<sup className="team-score-container-tiebreak">
									{ set.tie_break_score1 }
								</sup>
							</div>
	
							<div className="team-score__wrapper">
								<p className="team-score-number">
									{ set.score2 }
								</p>

								<sup className="team-score-container-tiebreak">
									{ set.tie_break_score2 }
								</sup>
							</div>
						</div>
					)
				})
			} else {
				return sets.map((set, index) => {
					return (
						<div className="team-score-container--double" key={index} onClick={() => goToMatch(match)}>
							<sup className="team-score-container-tiebreak--double">
								{ pairType === 'firstPair' ? set.tie_break_score1 : set.tie_break_score2 }
							</sup>

							<p className="team-score-number--double">
								{ pairType === 'firstPair' ? set.score1 : set.score2 }
								<sup className="olympicplus-grid__match-set-tiebreak">
									{ pairType === 'firstPair' ? set.tie_break_score1 : set.tie_break_score2 }
								</sup>
							</p>
						</div>
					)
				})
			}
		} else {
			return (
				<div className="team-score-container">
					<div
						style={{width: '50px', height: '55px', cursor: 'pointer'}}
						onClick={() => goToMatch(match)}
					></div>
				</div>
			)
		}
	}

	function matchupLineOne(cell) {
		if (cell.winner) {
			return true
		} else {
			return false
		}
	}

	function matchupLineRound(column, pair) {
		let style = {}

		if ([1, 0.5, 'Result'].includes(column.roundName)) {
			style = { display: 'none' }
		} else {
			const sizeFirst = pair.match[0]?.size
			const sizeSecond = pair.match[1]?.size
			const combinedSize = sizeFirst + sizeSecond

			if (type === TYPE_SINGLES) {
				if (combinedSize === 16) {
					style = { height: '500px' }
				} else if (combinedSize === 8) {
					style = { height: '250px' }
				} else if (combinedSize === 4) {
					style = { height: '125px' }
				} else if (combinedSize === 2) {
					style = { height: '64px' }
				}
			} else {
				if (combinedSize === 16) {
					style = { height: '915px' }
				}else if (combinedSize === 8) {
					style = { height: '459px' }
				} else if (combinedSize === 4) {
					style = { height: '235px' }
				} else if (combinedSize === 2) {
					style = { height: '115px' }
				}
			}
		}

		return style
	}

	return (
		<div className="bracket">
			{
				converterApiToOlympicFormat(grid).map((column, columnIndex) => (
					<div className="round" key={columnIndex}>
						<div
							className={
								`round-details ${column.roundName === RESULT_COLUMN_NAME ? 'round-details--round-right' : ''} ${columnIndex === 0 ? 'round-details--round-left' : ''}`
							}
							style={{ minWidth: column.roundName !== RESULT_COLUMN_NAME ? gridNameColumnWidth + 15 + 'px' :  gridNameColumnWidth + 45 + 'px'}}
						>
							{
								columnIndex === 0 &&
									<div className="round-details-type">
										{ generateGroupOrLevelsName(group.order_number - 1) }
									</div>
							}

							<p
								className={`round-details-number ${column.roundName === RESULT_COLUMN_NAME ? 'round-details-number--big' : ''}`}
								style={{ minWidth: column.roundName !== RESULT_COLUMN_NAME ? gridNameColumnWidth + 15 + 'px' :  gridNameColumnWidth + 45 + 'px'}}
							>
								{
									column.roundName !== RESULT_COLUMN_NAME ?
										column.roundName > 1 ?
											`1/${column.roundName}`
											:
											t(FINAL_COLUMN_NAME)
										:
										t(column.roundName)
								}
							</p>
						</div>

						<div className="round-container">
							<div className="round-wrapper">
								{
									column.matches.map((pair, pairIndex) => (
										<div className="matchup-container" key={pairIndex} style={matchupContainer(pair)}>
											<div className="matchup-wrapper">
												{
													pair.match.map((cell, cellIndex) => (
														(column.roundName !== 'Result' || pair.match.indexOf(cell) % 2 === 0) && (
															<div className="matchup" key={cellIndex} style={{ height: matchupHeight(cell) }}>
																{
																	matchupLineOne(cell) &&
																		<div className="matchup-line-one"></div>
																}

																<div className="team-container">
																	{
																		column.roundName !== 'Result' ?
																			getSidesLayout(cell, column.roundName, columnIndex)
																			:
																			getParticipantForResult(pair.match)
																	}
																</div>
															</div>
														)
													))
												}
											</div>

											<div className="matchup-line" style={matchupLineRound(column ,pair)}></div>
										</div>
									))
								}
							</div>
						</div>
					</div>
				))
			}
		</div>
	)
}

export default OlympicPlus2Grid