import { TYPE_SINGLES } from '../../utils/constants'
import { getNewUid } from '../../utils/functions'
import { containsLetter, formatName } from '../../utils/functions2'

function validateNewPLayersList(players, tournamentList, type, communityPlayers) {
	const cleanedPlayers = getCleanedPlayersFromSpaces(players)
	let currentList = [...cleanedPlayers]
	const emptyField = 'Заполните фамилию'
	const isBusy = 'Участник уже есть в турнире'
	const notUnique = 'В списке есть такой участник'
	const noLetterInLastName = 'В фамилии должна быть буква'
	const noLetterInFirstName = 'В имени должна быть буква'
	let isValid = true
	const foundInCommunity = {}

	for (let i = 0; i < cleanedPlayers.length; i++) {
		const player = { ...cleanedPlayers[i] }

		const needToSkip = skipPlayer(i, cleanedPlayers, player, type)

		if (needToSkip) {
			currentList = currentList.filter((item) => item.index !== player.index)
			continue
		}

		if (player.lastName.length < 1) {
			player.error = emptyField
			isValid = false
			currentList = replacePlayerInArray(currentList, player)
			continue
		}

		if (!containsLetter(player.lastName)) {
			player.error = noLetterInLastName
			isValid = false
			currentList = replacePlayerInArray(currentList, player)
			continue
		}

		if (!containsLetter(player.lastName) || (player.firstName && !containsLetter(player.firstName))) {
			player.error = noLetterInFirstName
			isValid = false
			currentList = replacePlayerInArray(currentList, player)
			continue
		}

		const foundPlayers = findPlayerInCommunity(player, communityPlayers)

		if (foundPlayers.length > 0) {
			// создаем объект массивов найденых игроков, где ключи это индексы игроков
			foundInCommunity[`${player.index}`] = foundPlayers
			player.inCommunity = true

			const existsInTournament = findPlayerInTournament(foundInCommunity, tournamentList, type, player.index)

			if (existsInTournament.length > 0) {
				player.error = isBusy
				isValid = false
			}
		}

		const notUniquePlayer = isUniquePlayer(currentList, player)

		if (notUniquePlayer) {
			player.error = !player.error ? notUnique : player.error
			isValid = false
		}

		currentList = replacePlayerInArray(currentList, player)
	}

	return { checkedList: currentList, isValid, foundInCommunity }
}

function getCleanedPlayersFromSpaces(players) {
	return players.map(item => {
		const firstNamStr = formatName(item.firstName)
		const secondNamStr = formatName(item.lastName)

		return {
			...item,
			firstName: firstNamStr,
			lastName: secondNamStr
		}
	})
}

function replacePlayerInArray(currentList, player) {
	return currentList.map((item) => {
		if (item.index === player.index) {
			return player
		}

		return item
	})
}

function skipPlayer(index, array, input, type) {
	// выбираем какие инпуты пропустить

	if (type === TYPE_SINGLES) {
		if (input.lastName.length < 1 && input.firstName.length < 1) {
			// если текущий инпут пустой, то пропускаем его
			return true
		}
	} else {
		// если пара пустая, то пропускаем эти инпуты
		const oddIndex = (index + 1) % 2 === 0
		if (!oddIndex && input.lastName.length < 1 && input.firstName.length < 1) {
			const nextInput = array[index + 1]

			return nextInput && nextInput.lastName.length < 1 && nextInput.firstName.length < 1
		} else if (oddIndex && input.lastName.length < 1 && input.firstName.length < 1) {
			const prevInput = array[index - 1]

			return prevInput && prevInput.lastName.length < 1 && prevInput.firstName.length < 1
		}
	}

	return false
}

function isUniquePlayer(players, player) {
	return players.some(item =>
		item.lastName === player.lastName &&
		item.firstName === player.firstName &&
		item.index !== player.index
	)
}

function findPlayerInTournament(existInCommunity, tournamentList, type, playerIndex) {
	const foundedPlayers = existInCommunity[`${playerIndex}`]

	return tournamentList.filter(item => foundedPlayers.some(el => {
		if (type === TYPE_SINGLES) {
			return item.uid === el.uid
		} else {
			return item.player1.uid === el.uid || item.player2.uid === el.uid
		}
	}))
}

function getDoubles(players) {
	const currentList = [...players]
	const pairs = []
	let withoutPair = []

	for (let i = 0; i < currentList.length; i += 2) {
		if (i + 1 < players.length) {
			const newPair = {
				uid: getNewUid(),
				player1: players[i],
				player2: players[i + 1]
			}
			pairs.push(newPair)
		} else {
			withoutPair = players[i]
		}
	}

	return { pairs, withoutPair }
}

function findPlayerInCommunity(player, listOfPlayers) {
	const { lastName, firstName } = player

	return listOfPlayers.filter(item => {
		const lastNameDB = item?.last_name
		const firstNameDB = item?.first_name

		if (lastName && !firstName) {
			return lastNameDB === lastName

		} else {
			const itemStr1 = `${lastNameDB}${firstNameDB}`
			const searchStr = `${lastName}${firstName}`

			return itemStr1 === searchStr
		}
	})
}

export {
	validateNewPLayersList,
	getDoubles,
	getCleanedPlayersFromSpaces
}
